@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Titillium+Web:wght@300;400;600;700;900&display=swap');

html {
  height: 100%;
}

body {
  /* height: 100%;
  margin: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background: linear-gradient(300deg, #10bddf 15%, #4a3ce7 100%); */
  background: -webkit-gradient(linear, right bottom, left top, from(#10bddf), to(#4a3ce7)) fixed;
}

.tail {
  padding: 1rem 2rem;
  color: white;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 600;
  font-size: 1.1rem;
  width: 100%;
  text-align: center;
}

.tail p {
  margin-bottom: 0.1rem;
}

.tail2 {
  padding: 1rem 2rem;
  color: white;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 600;
  font-size: 1.1rem;
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 0px;
}

.tail2 p {
  margin-bottom: 0.1rem;
}


.logo {
  background-image: url("../images/bunt_logo.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0px;
  margin: 1.5rem;
  width: 200px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
}

.ui.color1.button {
  background-color: #3442c4;
  color: gold;
}

.ui.color1.button:hover{
  background-color: #3442c4;
  color: gold(241, 234, 234);
}

.ui.color1.button:active{
  background-color: #3442c4;
  color: gold;
}

.ui.color1.button::selection{
  background-color: #3442c4;
  color: gold;
}

.Intro-Context{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 10rem;
  margin-top: 16rem;
}


.Join-Context{
  /* background-color: #60b2e8;
  background-image: linear-gradient(135deg, #60b2e8 0%, #9599E2 100%); */
  margin-bottom: 0.5rem;
  text-transform: uppercase;
}

.Summary-Context{
  /* background-color: #60b2e8;
  background-image: linear-gradient(135deg, #60b2e8 0%, #9599E2 100%); */
  margin-bottom: 4rem;
}

.Summary-Context p{
  /* background-color: #60b2e8;
  background-image: linear-gradient(135deg, #60b2e8 0%, #9599E2 100%); */
 font-size: 1.5rem;
 color: white;
}

.Summary-Context span{
  /* background-color: #60b2e8;
  background-image: linear-gradient(135deg, #60b2e8 0%, #9599E2 100%); */
  color: #E3C01E; 
  font-weight: bolder;
}

.Join-Context p{
  /* background-color: #60b2e8;
  background-image: linear-gradient(135deg, #60b2e8 0%, #9599E2 100%); */
 font-size: 3rem;
 color: white;
 font-family: 'Nunito Sans', sans-serif;
}

.Whitelisting-Context{
  /* background-color: #60b2e8;
  background-image: linear-gradient(135deg, #60b2e8 0%, #9599E2 100%); */
  margin-bottom: 3rem;

}

.Whitelisting-Context p{
  /* background-color: #60b2e8;
  background-image: linear-gradient(135deg, #60b2e8 0%, #9599E2 100%); */
 font-size: 1.5rem;
 color: gold;
 font-weight: bold;
 margin-top: 2rem;
}

.Whitelisting-Context h2{
 font-size: 2.5rem;
 color: white;
 font-family: 'Nunito Sans', sans-serif;
 margin-bottom: 1rem;
 margin: 0 5rem;
}

.Connect-Wallet-Intro-Button {
  width: 15rem;
  font-size: 0.9rem;
  border:solid 1px white;
  border-radius: .4rem;
  padding: 1rem 1.5rem;
  background: #3442c4;
  font-weight: 300;
  font-family: 'Nunito Sans', sans-serif;
  text-transform: uppercase;
  margin: 2rem;
  color: white;
}

@media (max-width:480px) {

  .logo {
    background-image: url("../images/bunt_logo.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0px;
    margin: 1rem;
    width: 120px;
    height: 30px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .Join-Context{
    /* background-color: #60b2e8;
    background-image: linear-gradient(135deg, #60b2e8 0%, #9599E2 100%); */
    margin-bottom: 1.5rem;
    text-transform: uppercase;
  }


  .Summary-Context p{
    /* background-color: #60b2e8;
    background-image: linear-gradient(135deg, #60b2e8 0%, #9599E2 100%); */
  font-size: 1rem;
  }

  .Join-Context p{
    /* background-color: #60b2e8;
    background-image: linear-gradient(135deg, #60b2e8 0%, #9599E2 100%); */
   font-size: 2rem;
   text-transform: uppercase;
    }

  .Whitelisting-Context{
      /* background-color: #60b2e8;
      background-image: linear-gradient(135deg, #60b2e8 0%, #9599E2 100%); */
      margin-bottom: 1rem;
    }
    
  .Whitelisting-Context p{
     font-size: 1.2rem;
     color: gold;
     font-weight: bold;
     margin: 4rem;
     padding-bottom: 2rem;
    }
    
  .Whitelisting-Context h2{
     font-size: 2rem;
     color: white;
     font-family: 'Nunito Sans', sans-serif;
     margin: 0 3rem 3rem 3rem;
    }

    .Intro-Context{
      margin: 0 4rem;
      margin-top: 14rem;
    }

    .Connect-Wallet-Intro-Button {
      width: 14rem;
      font-size: 0.8rem;
    }

    .tail {
      font-size: 0.9rem;
      width: 100%;
      text-align: left;
    }
    
    .tail p {
      margin-bottom: 0.1rem;
    }

    .tail2 {
      font-size: 0.9rem;
      width: 100%;
      text-align: left;
    }
    
    .tail2 p {
      margin-bottom: 0.1rem;
    }
  
}

.Account-Control {
  position: absolute;
  top: 0;
  right: 0;
  margin: 1.5rem;
}

.Connect-Wallet{
  /* background-color: #60b2e8;
  background-image: linear-gradient(135deg, #60b2e8 0%, #9599E2 100%); */
  height: 6rem;
}

.Connect-Wallet form {
  float: right;
}

.Close-Sheet {
  position: absolute;
  top: 0;
  right: 0;
  margin: 1.5rem 1rem;
}

.Connect-Wallet button {
  width: 14rem;
  font-size: 0.9rem;
  border:solid 2px white;
  border-radius: .4rem;
  padding: 1rem 1.5rem;
  background: rgba(255, 255, 255,0);
  font-weight: 300;
  font-family: 'Nunito Sans', sans-serif;
  text-transform: uppercase;
  margin: 1.5rem;
}

.Connect-Wallet button:hover {
  background: rgba(255, 255, 255,0);
  cursor: pointer;
}

@media (max-width:480px) {
  .Connect-Wallet button {
    width: 12rem;
    font-size: 0.7rem;
    border:none;
    margin: 1rem 0rem;
  }

  .Account-Control {
    position: absolute;
    top: 0;
    right: 0;
    margin: 1rem 0.5rem;
  }
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.App-header {
  padding: 1rem 0 5rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  margin-top: 6rem;
}

@media (max-width:480px) {
  .App-header {
    padding: 6rem 0 5rem 0;
    margin-top: auto;
  }
}

.App-header h1 {
  margin: 0;
  font-size: 2.5rem;
  font-family: 'Nunito Sans', sans-serif;
}

@media (max-width:480px) {
  .App-header h1 {
    margin: 0;
    font-size: 2rem;
    font-family: 'Nunito Sans', sans-serif;
  }
}

.App-header p {
  margin-top: 1rem;
  font-size: 1.3rem;
}

@media (max-width:480px) {
  .App-header p {
    margin-top: .5rem;
    font-size: 1rem;
  }
}

#App-link {
  color: #61dafb;
}

.App-subject {
  width: 100%;
  display: flex;
  align-content: flex-start;
  flex-direction: column;
  text-align: center;
}

.App-subject p {
  color: gold;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 400;
}

.App-content {
  width: 100%;
  margin: auto;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin-bottom: 2rem;
}

@media (max-width:480px) {
  .App-content {
    width: 100%;
    margin: auto;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-top: -4rem;
    margin-bottom: 4rem;
  }
}

