@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Titillium+Web:wght@300;400;600;700;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-size: 2em;
  -webkit-margin-before: 1.1em;
          margin-block-start: 1.1em;
}
form {
  display: flex;
  justify-content: center;
  align-items: center;
}

input {
  flex-grow: 6;
  border: 1px solid #D8DBE2;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 1rem 1.5rem;
  font-size: 1rem;
}

button {
  width: 9rem;
  background: #4E5DE4;
  color: white;
  font-weight: 500;
  font-family: 'Nunito Sans', sans-serif;
  text-transform: uppercase;
  border: solid 1px #4E5DE4;
  border-radius: .25rem;
  padding: 1rem 1.5rem;
  margin-left: .5rem;
}

@media screen and (max-width: 480px) {
  button {
    width: 8rem;
    padding: 1rem 1.5rem;
    font-size: 0.7rem;
  }
}

button:hover {
  background: #3442c4;
  cursor: pointer;
}

.Container-TokenSale {
  background: white;
  border-radius: 1rem;
  padding: 3rem;
  padding-bottom: 12rem;
  box-shadow: 0px 8px 16px rgba(44, 55, 75, 0.16);
  z-index: 2;
  margin: 1.5rem;
}

.Container-TokenSaleStats {
  border-radius: 1rem;
  padding: 3rem;
  box-shadow: 0px 8px 16px rgba(44, 55, 75, 0.16);
  z-index: 2;
  margin: 0 1.5rem;
  margin-top: -3rem;
  width: 75%;
  color: white;
  background-color:  rgba(44, 55, 75, 0.16); 
}

.tokensale-intro {
  display: flex;
  flex-direction: row;
  margin-bottom: 3rem;
}

.tokensale-welcome {
  width: 75%;
  margin: 0 4rem 3rem 0;
}

.tokensale-welcome-cta {
  font-size: 2rem;
  font-family: 'Nunito Sans', sans-serif;
}

.tokensale-welcome-summary {
  font-size: 1.3rem;
  font-family: 'Titillium Web', sans-serif;
  margin-bottom: 2rem;
}

.tokensale-welcome button {
  width: 15rem;
  font-size: 0.9rem;
  border:solid 1px white;
  border-radius: .4rem;
  padding: 1rem 1.5rem;
  background: #3442c4;
  font-weight: 300;
  font-family: 'Nunito Sans', sans-serif;
  text-transform: uppercase;
  /* margin: 2rem; */
}

.tokensale-finalized {
  color: gold;
  font-size: 1.4rem;
  font-weight: 600;
  font-family: 'Titillium Web', sans-serif;
}

.tokensale-wallet {
  float: right;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: 'Titillium Web', sans-serif;
  text-transform: uppercase;
  margin-bottom: 3rem;
  width: 25%;
}

.tokensale-wallet-container{
  width: 100%;
}

.tokensale-wallet-title {
  color: gold;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  font-weight: 600;
}

.tokensale-wallet-balance {
  display: flex;
  flex-direction: row;
  font-size: 3rem;
  margin-bottom: 0rem;
  font-weight: 600;
  width: 100%;
}

.tokensale-wallet-balance-amount {
  width: 100%;
}

.tokensale-wallet-logo {
  text-align: left;
  width: 30px;
  height: 30px;
  width: 20%;   
  padding-top: 1.3rem;
}

.tokensale-wallet-logo img {
  width: 25px;
  height: 25px;
}

.tokensale-wallet-balance span {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

.tokensale-Progress{
  width: 100%;
  font-family: 'Titillium Web', sans-serif;
  text-transform: uppercase;
  text-align: left;
  margin-bottom: 3rem;
}

.tokensale-Progress-title {
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
  padding-bottom: 1rem;
}

.tokensale-Progress-header {
  color: white;
  font-size: 1.3rem;
  font-weight: 700;
  padding-bottom: 2rem;
}


@media screen and (max-width: 480px) {
  .Container-TokenSaleStats {
    border-radius: 1rem;
    padding: 3rem;
    box-shadow: 0px 8px 16px rgba(44, 55, 75, 0.16);
    margin: 0.5rem;
    margin-top: 1rem;
    z-index: 2;
    width: auto;
    color: white;
    background-color:  transparent; 
  }

  .Container-TokenSale {
    background: white;
    border-radius: 1rem;
    padding: 3rem;
    padding-bottom: 12rem;
    box-shadow: 0px 8px 16px rgba(44, 55, 75, 0.16);
    z-index: 2;
    margin: 1rem auto;
    display: flex;
    flex-direction: column;

  }

  .TokenSale-Options {
    margin-bottom: 1rem;
  }

  .tokensale-Progress{
      text-align: center;
  }

  .tokensale-Progress-title {
    align-items: center;
    font-size: 1.2rem;
    padding-bottom: 1.4rem;
  }

  .tokensale-intro {
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
  }

  .tokensale-welcome {
    width: 100%;
    text-align: center;
  }

  .tokensale-wallet {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    margin-top: 2rem;
  }

  .tokensale-wallet-balance {
    font-size: 3rem;
  }

  .tokensale-wallet-balance-amount {
    text-align: center;
  }

}

.Container-TokenSaleInfo {
  border-radius: 1rem;
  padding: 1.5rem 2rem;
  box-shadow: 0px 8px 16px rgba(44, 55, 75, 0.16);
  z-index: 2;
  margin: 0 1.5rem 0 0.5rem;
  margin-top: -3rem;
  width: 25%;
  font-family: 'Titillium Web', sans-serif;
  text-transform: uppercase;
  color: white;
  background-color:  rgba(44, 55, 75, 0.16);  
}

.tokensale-finance-token-container{
  margin-bottom: 2rem;
}

.tokensale-finance-token {
  width: 100%;
  display: flex;
  flex-direction: row;
  font-family: 'Nunito Sans', sans-serif;
  text-transform: uppercase;
  font-size: 1.2rem;
}

.tokensale-finance-token-header {
  width: 100%;
  margin-top: 0.2rem;
  font-family: 'Nunito Sans', sans-serif;
  text-transform: uppercase;
}

.tokensale-finance-token img{
  margin-top: 0rem;
  width: 2.5rem;
  height: 2.5rem;
  float: right;
}

.tokensale-finance-tokenomics{
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
}

.tokensale-finance-tokenomics-header{
  width: 60%;
  float: left;
  text-align: left;
  font-size: 1.1rem;
}

.tokensale-finance-tokenomics-content{
  width: 40%;
  float: right;
  text-align: right;
  font-weight: 700;
  font-size: 1.1rem;
}

@media screen and (max-width: 480px) {
.Container-TokenSaleInfo {
  color: white;
  background-color:  rgba(44, 55, 75, 0.16);  
  border-radius: 1rem;
  padding: 1.5rem 2rem;
  box-shadow: 0px 8px 16px rgba(44, 55, 75, 0.16);
  margin: 1rem;
  margin-top: 1rem;
  z-index: 2;
  width: auto;
}
}

h4 {
  margin: 0;
  text-align: center;
}

.tokensale-finance {
  font-family: 'Titillium Web', sans-serif;
  text-align: left;
  height: 100px;
}

.tokensale-finance-header { 
  font-size: 1.2em;
  line-height: 2em;
  font-weight: 400;
  word-break: break-word;
  overflow-wrap: break-word;
}
.tokensale-finance-content {
  font-size: 1.8em;
  line-height: 1.1em;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  word-break: break-word;
  overflow-wrap: break-word;
}

@media screen and (max-width: 480px) {
  .tokensale-finance-content {
    font-size: 1.2em;
    line-height: 0.9em;
    font-weight: 550;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    word-break: break-word;
    overflow-wrap: break-word;
  }
}

.tokensale-finance-content p{
  font-size: 0.8em;
  line-height: 1.4em;
  font-weight: 300;
  margin: 0;
  margin-left: 10px;
}

@media screen and (max-width: 480px) {
  .tokensale-finance-content p{
    font-size: 0.8em;
    line-height: 1.4em;
    font-weight: 300;
    margin: 0;
    margin-left: 0;
  }
}

.Wallet-Connect {
  width: 100%;
}

.tokensale-finance form {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tokensale-finance button {
  width: 15rem;
  background: #4E5DE4;
  color: white;
  font-weight: 300;
  font-family: 'Nunito Sans', sans-serif;
  text-transform: uppercase;
  border: solid 1px #4E5DE4;
  border-radius: .25rem;
  padding: 1rem 1.5rem;
}

.Container-TokenSale button {
  width: 8rem;
  background: #4E5DE4;
  color: white;
  font-weight: 200;
  font-family: 'Nunito Sans', sans-serif;
  text-transform: uppercase;
  border: solid 1px #4E5DE4;
  border-radius: .25rem;
  padding: 1rem 1.5rem;
  font-size: 0.8rem;
}

/* .Container-TokenSaleStats button {
  width: 12rem;
  background: white;
  color: #4E5DE4;
  font-weight: 300;
  font-size: 0.9rem;
  font-family: 'Nunito Sans', sans-serif;
  text-transform: uppercase;
  border: solid 1px #4E5DE4;
  border-radius: .25rem;
  padding: 1rem 1.5rem;
} */

.Amount-Input {
    margin-right: 10px;
}


.tokensale-finance button:hover {
  background: #3442c4;
  cursor: pointer;
}

#purchase-options {
  margin-left: 10px;
}

.Tokensale-Page {
  width: 40%;
  height: 50rem;
  margin-top: 3rem;
}

.Tokensale-Page-Input {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2rem 2rem;
  border: solid .8px silver;
  border-radius: 1.5rem;
  margin: 0.2rem;
}

.Tokensale-Page-Input input{
  border: none;
  outline: none;
  font-weight: bolder;
  color: #3442c4;
}

.Tokensale-Page-Info {
  margin: 0.5rem;
  font-weight: bold;
}

.Tokensale-Page-Option {
  display: flex;
  flex-direction: column;
}

.Tokensale-Page form{
  width: 98%;
}

.Tokensale-Page button{
  width: 100%;
  background: #4E5DE4;
  color: white;
  font-weight: 300;
  font-size: 0.9rem;
  font-family: 'Nunito Sans', sans-serif;
  text-transform: uppercase;
  border: solid 1px white;
  border-radius: 1rem;
  margin-top: 2rem;
  /* padding: 1rem 1rem; */
}

.Tokensale-Page button:disabled,
.Tokensale-Page button[disabled]{
  background-color: grey;
}

@media screen and (max-width: 480px) {
  .Tokensale-Page {
    width: 95%;
  }

  .Tokensale-Page-Input {
    padding: 1.5rem 2rem;
  }

  .Tokensale-Page-Input {
    border-radius: 1rem;
  }
}


.ConfirmTokenSale{
  width: 30%;
  height: 50rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.ConfirmTokenSale-Header{
  margin-top: 3rem;
  margin-bottom: 2rem;
  font-size: 1rem;
  font-family: 'Nunito Sans', sans-serif;
  text-transform: uppercase;
}

.ConfirmTokenSale-Purchase-Container{
  margin-top: 2rem;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin: 1rem;
  font-size: 2rem;
  font-family: 'Nunito Sans', sans-serif;
  text-transform: uppercase;
  color: #3442c4;
}

.ConfirmTokenSale-Purchase{
  margin: 1rem;
  font-size: 2.6rem;
  font-family: 'Nunito Sans', sans-serif;
  text-transform: uppercase;
  color: #3442c4;
}

.ui.colorTrans.button {
    background-color:  rgba(44, 55, 75, 0.16);   
    width: 3rem;
}

.ConfirmTokenSale-Total-Purchase {
  margin-top: 0.5rem;
  margin-bottom: 3rem;
  font-size: 1rem;
  text-transform: uppercase;
  font-family: 'Nunito Sans', sans-serif;
  color: green;
}

.ConfirmTokenSale-Total-Purchase span {
  color: green;
}

.ConfirmTokenSale-Payment {
  margin-top: 2.2rem;
  margin-bottom: 3rem;
  font-size: 1.3rem;
  font-family: 'Nunito Sans', sans-serif;
  text-transform: uppercase;
}

.ConfirmTokenSale-Payment span{
  font-size: 1.3rem;
  font-family: 'Nunito Sans', sans-serif;
  text-transform: uppercase;
  color: #3442c4;
}


.ConfirmTokenSale-Note {
  margin-top: 2rem;
  font-size: 1rem;
  /* text-transform: uppercase; */
  font-family: 'Titillium Web', sans-serif;
  color: dimgray;
}

.ConfirmTokenSale-Confirmed {
  margin-top: 5rem;
  font-size: 0.8rem;
  font-family: 'Nunito Sans', sans-serif;
  text-transform: uppercase;
  color: dimgray;
}

.ConfirmTokenSale form{
  width: 98%;
}

.ConfirmTokenSale button{
  width: 100%;
  background: #4E5DE4;
  color: white;
  font-weight: 300;
  font-size: 0.9rem;
  font-family: 'Nunito Sans', sans-serif;
  text-transform: uppercase;
  border: solid 1px white;
  border-radius: 1rem;
  margin-top: 1rem;
  /* padding: 1rem 1rem; */
}


@media screen and (max-width: 480px) {
  .ConfirmTokenSale {
    width: 90%;
  }

  .ConfirmTokenSale-Note {
    font-size: 0.8rem;
  }

}
html {
  height: 100%;
}

body {
  /* height: 100%;
  margin: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background: linear-gradient(300deg, #10bddf 15%, #4a3ce7 100%); */
  background: -webkit-gradient(linear, right bottom, left top, from(#10bddf), to(#4a3ce7)) fixed;
}

.tail {
  padding: 1rem 2rem;
  color: white;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 600;
  font-size: 1.1rem;
  width: 100%;
  text-align: center;
}

.tail p {
  margin-bottom: 0.1rem;
}

.tail2 {
  padding: 1rem 2rem;
  color: white;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 600;
  font-size: 1.1rem;
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 0px;
}

.tail2 p {
  margin-bottom: 0.1rem;
}


.logo {
  background-image: url(/static/media/bunt_logo.f6d7ccd8.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0px;
  margin: 1.5rem;
  width: 200px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
}

.ui.color1.button {
  background-color: #3442c4;
  color: gold;
}

.ui.color1.button:hover{
  background-color: #3442c4;
  color: gold(241, 234, 234);
}

.ui.color1.button:active{
  background-color: #3442c4;
  color: gold;
}

.ui.color1.button::selection{
  background-color: #3442c4;
  color: gold;
}

.Intro-Context{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 10rem;
  margin-top: 16rem;
}


.Join-Context{
  /* background-color: #60b2e8;
  background-image: linear-gradient(135deg, #60b2e8 0%, #9599E2 100%); */
  margin-bottom: 0.5rem;
  text-transform: uppercase;
}

.Summary-Context{
  /* background-color: #60b2e8;
  background-image: linear-gradient(135deg, #60b2e8 0%, #9599E2 100%); */
  margin-bottom: 4rem;
}

.Summary-Context p{
  /* background-color: #60b2e8;
  background-image: linear-gradient(135deg, #60b2e8 0%, #9599E2 100%); */
 font-size: 1.5rem;
 color: white;
}

.Summary-Context span{
  /* background-color: #60b2e8;
  background-image: linear-gradient(135deg, #60b2e8 0%, #9599E2 100%); */
  color: #E3C01E; 
  font-weight: bolder;
}

.Join-Context p{
  /* background-color: #60b2e8;
  background-image: linear-gradient(135deg, #60b2e8 0%, #9599E2 100%); */
 font-size: 3rem;
 color: white;
 font-family: 'Nunito Sans', sans-serif;
}

.Whitelisting-Context{
  /* background-color: #60b2e8;
  background-image: linear-gradient(135deg, #60b2e8 0%, #9599E2 100%); */
  margin-bottom: 3rem;

}

.Whitelisting-Context p{
  /* background-color: #60b2e8;
  background-image: linear-gradient(135deg, #60b2e8 0%, #9599E2 100%); */
 font-size: 1.5rem;
 color: gold;
 font-weight: bold;
 margin-top: 2rem;
}

.Whitelisting-Context h2{
 font-size: 2.5rem;
 color: white;
 font-family: 'Nunito Sans', sans-serif;
 margin-bottom: 1rem;
 margin: 0 5rem;
}

.Connect-Wallet-Intro-Button {
  width: 15rem;
  font-size: 0.9rem;
  border:solid 1px white;
  border-radius: .4rem;
  padding: 1rem 1.5rem;
  background: #3442c4;
  font-weight: 300;
  font-family: 'Nunito Sans', sans-serif;
  text-transform: uppercase;
  margin: 2rem;
  color: white;
}

@media (max-width:480px) {

  .logo {
    background-image: url(/static/media/bunt_logo.f6d7ccd8.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0px;
    margin: 1rem;
    width: 120px;
    height: 30px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .Join-Context{
    /* background-color: #60b2e8;
    background-image: linear-gradient(135deg, #60b2e8 0%, #9599E2 100%); */
    margin-bottom: 1.5rem;
    text-transform: uppercase;
  }


  .Summary-Context p{
    /* background-color: #60b2e8;
    background-image: linear-gradient(135deg, #60b2e8 0%, #9599E2 100%); */
  font-size: 1rem;
  }

  .Join-Context p{
    /* background-color: #60b2e8;
    background-image: linear-gradient(135deg, #60b2e8 0%, #9599E2 100%); */
   font-size: 2rem;
   text-transform: uppercase;
    }

  .Whitelisting-Context{
      /* background-color: #60b2e8;
      background-image: linear-gradient(135deg, #60b2e8 0%, #9599E2 100%); */
      margin-bottom: 1rem;
    }
    
  .Whitelisting-Context p{
     font-size: 1.2rem;
     color: gold;
     font-weight: bold;
     margin: 4rem;
     padding-bottom: 2rem;
    }
    
  .Whitelisting-Context h2{
     font-size: 2rem;
     color: white;
     font-family: 'Nunito Sans', sans-serif;
     margin: 0 3rem 3rem 3rem;
    }

    .Intro-Context{
      margin: 0 4rem;
      margin-top: 14rem;
    }

    .Connect-Wallet-Intro-Button {
      width: 14rem;
      font-size: 0.8rem;
    }

    .tail {
      font-size: 0.9rem;
      width: 100%;
      text-align: left;
    }
    
    .tail p {
      margin-bottom: 0.1rem;
    }

    .tail2 {
      font-size: 0.9rem;
      width: 100%;
      text-align: left;
    }
    
    .tail2 p {
      margin-bottom: 0.1rem;
    }
  
}

.Account-Control {
  position: absolute;
  top: 0;
  right: 0;
  margin: 1.5rem;
}

.Connect-Wallet{
  /* background-color: #60b2e8;
  background-image: linear-gradient(135deg, #60b2e8 0%, #9599E2 100%); */
  height: 6rem;
}

.Connect-Wallet form {
  float: right;
}

.Close-Sheet {
  position: absolute;
  top: 0;
  right: 0;
  margin: 1.5rem 1rem;
}

.Connect-Wallet button {
  width: 14rem;
  font-size: 0.9rem;
  border:solid 2px white;
  border-radius: .4rem;
  padding: 1rem 1.5rem;
  background: rgba(255, 255, 255,0);
  font-weight: 300;
  font-family: 'Nunito Sans', sans-serif;
  text-transform: uppercase;
  margin: 1.5rem;
}

.Connect-Wallet button:hover {
  background: rgba(255, 255, 255,0);
  cursor: pointer;
}

@media (max-width:480px) {
  .Connect-Wallet button {
    width: 12rem;
    font-size: 0.7rem;
    border:none;
    margin: 1rem 0rem;
  }

  .Account-Control {
    position: absolute;
    top: 0;
    right: 0;
    margin: 1rem 0.5rem;
  }
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.App-header {
  padding: 1rem 0 5rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  margin-top: 6rem;
}

@media (max-width:480px) {
  .App-header {
    padding: 6rem 0 5rem 0;
    margin-top: auto;
  }
}

.App-header h1 {
  margin: 0;
  font-size: 2.5rem;
  font-family: 'Nunito Sans', sans-serif;
}

@media (max-width:480px) {
  .App-header h1 {
    margin: 0;
    font-size: 2rem;
    font-family: 'Nunito Sans', sans-serif;
  }
}

.App-header p {
  margin-top: 1rem;
  font-size: 1.3rem;
}

@media (max-width:480px) {
  .App-header p {
    margin-top: .5rem;
    font-size: 1rem;
  }
}

#App-link {
  color: #61dafb;
}

.App-subject {
  width: 100%;
  display: flex;
  align-content: flex-start;
  flex-direction: column;
  text-align: center;
}

.App-subject p {
  color: gold;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 400;
}

.App-content {
  width: 100%;
  margin: auto;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin-bottom: 2rem;
}

@media (max-width:480px) {
  .App-content {
    width: 100%;
    margin: auto;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-top: -4rem;
    margin-bottom: 4rem;
  }
}


